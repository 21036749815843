import React, {useEffect, lazy, Suspense} from 'react'

import {useUser} from '../../authentication/context/userContext'
import FullPageSpinner from '../../../ui/FullPageSpinner'

const loadAuthenticatedApp = () => import('./AuthenticatedApp')
const AuthenticatedApp = lazy(loadAuthenticatedApp)
const UnauthenticatedApp = lazy(() =>
  import('../components/UnauthenticatedApp'),
)

function App() {
  const user = useUser()
  useEffect(() => {
    loadAuthenticatedApp()
  }, [])
  return (
    <Suspense fallback={<FullPageSpinner />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Suspense>
  )
}

export default App
