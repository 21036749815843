import React from 'react'
import firebase from '../../../infrastructure/firebase'

const AuthContext = React.createContext()

function AuthProvider(props) {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')

  const signIn = async (email, password) => {
    setLoading(true)
    try {
      await firebase.signIn(email, password)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(error.message)
    }
  }
  const signOut = firebase.signOut

  return (
    <AuthContext.Provider
      value={{signIn, signOut, loading, error}}
      {...props}
    />
  )
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export {AuthProvider, useAuth}
