import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

app.initializeApp(config)
const auth = app.auth()
const firestore = app.firestore()

window.firestore = firestore

class Firebase {
  static signIn(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }
  static signOut() {
    return auth.signOut()
  }
  static currentUser() {
    return auth.currentUser
  }
  static subscribeAuthStateChanged(callback) {
    return auth.onAuthStateChanged(callback)
  }
  static createCommunity(community) {
    return firestore.collection('communities').add(community)
  }
  static subscribeCommunities(callback) {
    return firestore.collection('communities').onSnapshot(snapshot => {
      const collection = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))
      callback(collection)
    })
  }
  static subscribeProperties(communityId, callback) {
    return firestore
      .collection('communities')
      .doc(communityId)
      .collection('properties')
      .orderBy('label')
      .onSnapshot(snapshot => {
        const collection = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        callback(collection)
      })
  }
  static async retrieveCommunities() {
    const snapshot = await firestore.collection('communities').get()
    const communities = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))
    return communities
  }
  static async retrieveCommunity(id) {
    const docRef = await firestore
      .collection('communities')
      .doc(id)
      .get()
    const community = {id: docRef.id, ...docRef.data()}
    return community
  }
  static async retrieveProperties(communityId) {
    const snapshot = await firestore
      .collection('communities')
      .doc(communityId)
      .collection('properties')
      .orderBy('label')
      .get()
    const properties = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))
    return properties
  }
  static async createProperty(communityId, property) {
    const docRef = await firestore
      .collection('communities')
      .doc(communityId)
      .collection('properties')
      .add(property)

    return docRef
  }
  static async countProperties(communityId) {
    const snapshot = await firestore
      .collection('communities')
      .doc(communityId)
      .collection('properties')
      .get()

    return snapshot.size()
  }
  static async retrieveNeighbors(communityId) {
    const snapshot = await firestore
      .collection('communities')
      .doc(communityId)
      .collection('neighbors')
      .get()
    const neighbors = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))
    return neighbors
  }
  static async createNeighbor(communityId, neighbor) {
    const docRef = await firestore
      .collection('communities')
      .doc(communityId)
      .collection('neighbors')
      .add(neighbor)

    return docRef
  }
}

export default Firebase
