import React from 'react'
import {ThemeProvider} from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import theme from './theme'

export const buildTheme = app => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {app}
  </ThemeProvider>
)
