import React, {Component, createContext, useContext} from 'react'
import firebase from '../../../infrastructure/firebase'
import FullPageSpinner from '../../../ui/FullPageSpinner'

export const UserContext = createContext(null)

class UserProvider extends Component {
  state = {
    user: null,
    isPending: true,
  }
  unsubscribe = null

  componentDidMount() {
    this.unsubscribe = firebase.subscribeAuthStateChanged(user => {
      this.setState({user, isPending: false})
    })
  }
  componentWillUnmount() {
    this.unsubscribe()
  }
  render() {
    if (this.state.isPending) {
      return <FullPageSpinner />
    }
    const {user} = this.state
    return <UserContext.Provider value={user} {...this.props} />
  }
}

function useUser() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }
  return context
}

export {UserProvider, useUser}
