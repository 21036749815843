import {
  PROPERTIES_LOAD_START,
  PROPERTIES_LOAD_END,
  PROPERTIES_CREATE_START,
  PROPERTIES_CREATE_END,
  SELECT_PROPERTY,
} from './types'

const defaultState = {
  entities: [],
  selected: null,
  isLoading: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case PROPERTIES_LOAD_START:
      return {...state, entities: [], isLoading: true}
    case PROPERTIES_LOAD_END:
      return {...state, entities: [...action.payload], isLoading: false}
    case PROPERTIES_CREATE_START:
      return {...state, isLoading: true}
    case PROPERTIES_CREATE_END:
      return {...state, isLoading: false}
    case SELECT_PROPERTY:
      return {...state, selected: action.payload}
    default:
      return state
  }
}
