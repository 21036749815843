import React from 'react'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    boxShadow: 'none',
    position: 'fixed',
    inset: 0,
    background: 'white',
    zIndex: 1100,
  },
}))

function FullPageSpinner() {
  const classes = useStyles()
  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <CircularProgress />
    </Box>
  )
}

export default FullPageSpinner
