import {
  NEIGHBOR_CREATE_START,
  NEIGHBOR_CREATE_END,
  NEIGHBORS_LOAD_START,
  NEIGHBORS_LOAD_END,
} from './types'

const defaultState = {
  entities: [],
  selected: null,
  isLoading: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case NEIGHBORS_LOAD_START:
      return {...state, entities: [], isLoading: true}
    case NEIGHBORS_LOAD_END:
      return {...state, entities: [...action.payload], isLoading: false}
    case NEIGHBOR_CREATE_START:
      return {...state, isLoading: true}
    case NEIGHBOR_CREATE_END:
      return {...state, isLoading: false}
    default:
      return state
  }
}
