import React from 'react'
import {Provider} from 'react-redux'
import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunkMiddleware from 'redux-thunk'
import {createLogger} from 'redux-logger'
import {composeWithDevTools} from 'redux-devtools-extension'

const loggerMiddleware = createLogger()

const appReducers = combineReducers({
  communities: require('../../application/communities/actions/reducers')
    .default,
  properties: require('../../application/properties/actions/reducers').default,
  neighbors: require('../../application/neighbors/actions/reducers').default,
})

let middleWares = applyMiddleware(thunkMiddleware)

if (process.env['NODE_ENV'] === 'development') {
  middleWares = composeWithDevTools(
    applyMiddleware(thunkMiddleware, loggerMiddleware),
  )
}

const store = createStore(appReducers, middleWares)

export const buildStore = app => <Provider store={store}>{app}</Provider>
